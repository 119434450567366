import React, { useEffect } from "react";
import "./DevOpsService2.css";
import { NavLink, useNavigate } from "react-router-dom";

function DevOpsService2() {
  const Navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="t-ultimate-container">
      <div className="t-container">
        <div
          style={{
            paddingTop: "30px",
            marginTop: 0,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <NavLink to="/">
            <img
              className="logo_testing"
              src={require("../../img/logo.png")}
              alt="logo"
            />
          </NavLink>
          <h2 className="t_head1">CI/CD</h2>
          <h2
            className="t_head1"
            style={{ paddingBottom: "50px", marginTop: "10px" }}
          >
            (Continuous Integration / Continuous Delivery)
          </h2>
        </div>
        <h2
          style={{ color: "yellow", fontSize: "25px", paddingBottom: "15px" }}
        >
          What is CI/CD?
        </h2>
        <p className="t-description">
          CI/CD is an approach used by software development teams to streamline
          the process of building and delivering software. It involves two key
          practices: Continuous Integration (CI) and Continuous Delivery (CD).
          <br />
          <br />
          Continuous Integration involves frequently merging code changes into a
          shared repository and automatically running tests to identify any
          issues. This process helps to detect errors early in the development
          cycle, so they can be addressed before they become bigger problems.
          <br />
          <br />
          Continuous Delivery builds on Continuous Integration by automatically
          deploying tested code changes to production environments, minimizing
          the time between code development and deployment. This approach helps
          teams to release software more frequently and with greater
          reliability. <br />
          <br />
          Together, CI/CD aims to automate the entire software delivery pipeline
          from code changes to production deployment. This helps development
          teams to accelerate their release cycles, improve the quality of their
          code and respond quickly to customer feedback.
        </p>

        <h2
          style={{
            color: "yellow",
            paddingTop: "30px",
            fontSize: "25px",
            paddingBottom: "15px",
          }}
        >
          Why every company should have a CI/CD pipeline?
        </h2>
        <p className="t-description">
          If the above details didn't convince you why every business should
          have this pipeline in your everyday practice, then here are some more
          reasons why every company should have this in place:
          <br />
          <br />
          1.
          <span
            style={{
              paddingLeft: "6px",
              fontWeight: "800",
              paddingRight: "6px",
            }}
          >
            Increased collaboration and efficiency:
          </span>
          A CI/CD pipeline encourages collaboration between developers, testers
          and operations teams and provides a standard process for software
          development and delivery. This helps to increase efficiency and reduce
          the time and effort required to release new features.
          <br />
          <br />
          2.
          <span
            style={{
              paddingLeft: "6px",
              fontWeight: "800",
              paddingRight: "6px",
            }}
          >
            Greater flexibility and scalability:
          </span>{" "}
          It can help companies to scale their software delivery process, as it
          allows for easier management and deployment of software across
          multiple environments. This is especially important for companies that
          are growing rapidly or have a large customer base. <br />
          <br />
          3.
          <span
            style={{
              paddingLeft: "6px",
              fontWeight: "800",
              paddingRight: "6px",
            }}
          >
            Faster time-to-market:{" "}
          </span>
          Automating the software delivery process means that new features and
          updates can be released more quickly and efficiently. This allows
          companies to respond to customer needs and market changes faster than
          competitors. <br />
          <br />
          4.
          <span
            style={{
              paddingLeft: "6px",
              fontWeight: "800",
              paddingRight: "6px",
            }}
          >
            Better quality user experience:
          </span>{" "}
          By integrating and testing code changes continuously, a CI/CD pipeline
          helps to identify and fix bugs and errors early in the development
          process. This leads to better quality software and reduces the risk of
          defects being introduced into production. <br />
          <br />
          5.
          <span
            style={{
              paddingLeft: "6px",
              fontWeight: "800",
              paddingRight: "6px",
            }}
          >
            Bigger profits:
          </span>{" "}
          By automating many of the time-consuming and error-prone tasks
          involved in software development and delivery, a CI/CD pipeline can
          help to reduce the overall cost of software development, improve the
          return on investment and create a smooth user experience. <br />
          <br />
          <br />
          Poor user experience in market analysis' have proven to be the biggest
          reasons for loss of customers.
          <br />
          <br />
          In summary, a CI/CD pipeline is essential for companies that want to
          stay competitive in the modern software development landscape.
          <br />
          <br />
          <br />
          <h2
            style={{ color: "yellow", fontSize: "25px", paddingBottom: "15px" }}
          >
            How our CI / CD services can assist your team?
          </h2>
          <h3 style={{ color: "white", paddingBottom: "15px" }}>
            When hiring a company to assist you in creating a CI/CD pipeline,
            here are several key factors to consider:
          </h3>
          <div className="modern-design-container">
            <div className="modern-design-container-left">
              Experience
              <br /> &<br /> Expertise
              <div className="left-element"></div>
              <div className="right-element"></div>
            </div>
            <div className="modern-design-container-right">
              <p>
                <p style={{ color: "blue" }}>
                  Look for consultants or companies with solid experience and
                  understanding of successful implementation.
                </p>
                <br />
                <br />
                Our team has a combined experience of fifteen years in CI/CD
                practices and twenty plus years in large project development. We
                specialize in CI/CD pipelines. We have a deep understanding of
                the technologies and tools involved in CI/CD and we can guide
                you in making the right decisions for your specific needs.
              </p>
            </div>
          </div>
          <div className="modern-design-container">
            <div
              className="modern-design-container-left"
              style={{ color: "pink" }}
            >
              Communication
              <br /> &<br /> Collaboration
              <div
                className="left-element"
                style={{ backgroundColor: "pink" }}
              ></div>
              <div
                className="right-element"
                style={{ backgroundColor: "pink" }}
              ></div>
            </div>
            <div className="modern-design-container-right">
              <p>
                <p style={{ color: "pink" }}>
                  Work with someone who can communicate clearly and open to
                  understanding your values, style and vision.
                </p>
                <br />
                <br />
                We understand the importance of communication and collaborative
                efforts. We have checklists and balances in place through Jira
                for clear communication and reaching your deadlines. In
                addition, you will always have direct access to our team with
                any details you need us to address timely. We are open to other
                tools you may use for communication and collaboration with your
                team and welcome your suggestions.
              </p>
            </div>
          </div>
          <div className="modern-design-container">
            <div
              className="modern-design-container-left"
              style={{ color: "green" }}
            >
              Customization
              <br /> &<br /> Scalability
              <div
                className="left-element"
                style={{ backgroundColor: "green" }}
              ></div>
              <div
                className="right-element"
                style={{ backgroundColor: "green" }}
              ></div>
            </div>
            <div className="modern-design-container-right">
              <p>
                <p style={{ color: "green" }}>
                  Look for someone who can understand your unique requirements
                  and who can create a pipeline that is scalable and adaptable
                  to your changing needs.
                </p>
                <br />
                <br />
                We can and will create a CI/CD pipeline that is customized to
                your specific needs and that can grow and evolve with your
                business over time. Our experience has made it easy for us to
                always be aware for the need of flexibility to scale. This is
                one area we excel in. With our checks and balances in place you
                will always be involved through the entire production of your
                pipeline. You will have access to the progress and are welcomed
                to add any notes or ask any questions along the way. We make it
                easy for you to review the production, verbalize any concerns
                and provide responses to you in a timely manner.
              </p>
            </div>
          </div>
          <div className="modern-design-container">
            <div
              className="modern-design-container-left"
              style={{ color: "yellow" }}
            >
              Support
              <br /> &<br /> Maintenance
              <div
                className="left-element"
                style={{ backgroundColor: "yellow" }}
              ></div>
              <div
                className="right-element"
                style={{ backgroundColor: "yellow" }}
              ></div>
            </div>
            <div className="modern-design-container-right">
              <p>
                <p style={{ color: "yellow" }}>
                  Finally, look for a consultant or company that offers ongoing
                  support and maintenance for your CI/CD pipeline.
                </p>
                <br />
                <br />
                We are available to help you troubleshoot any issues that arise.
                We are committed to your success over the long term and we can
                help you achieve your goals as your business grows and evolves.
                With our checks and balances process we can provide exceptional
                support and can also offer some exceptional developer tools for
                your team to improve and focus on developing
              </p>
            </div>
          </div>
          <p style={{ textAlign: "center" }}>
            We can evaluate and prevent major costs. There is so much to know
            with software that this service pays for itself many times over.
            <br />
            <br />
            Contact us specifically with your project request.
            <br />
            <br />
            <h2 style={{ fontSize: "25px" }}>
              Obtain a free quote & assessment on our CI/CD services
            </h2>
          </p>
        </p>
        <button
          style={{ marginTop: "25px" }}
          className="submit_btn"
          type="submit"
          onClick={() => Navigate("/devopsmodal")}
        >
          COMPLETE FORM ONLINE
        </button>
      </div>
    </div>
  );
}

export default DevOpsService2;
