import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./AIService.css";

function AIService() {
  const Navigate = useNavigate();

  return (
    <div className="bg-sales">
      <div className="t-container bg">
        <div
          style={{
            marginTop: 0,
            width: "100%",
            paddingBottom: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <NavLink to="/">
            <img
              className="logo_testing"
              src={require("../img/logo.png")}
              alt="logo"
            />
          </NavLink>
        </div>

        <h1 className="heading-ai">
          Leverage the Power of AI within your business
        </h1>

        <p style={{ marginBottom: "20px", marginTop: "5px", fontSize: "20px" }}>
          In today's technology-driven business landscape, adopting AI solutions
          is not just an option—it's a necessity for staying competitive. We
          understand this and that's why we're dedicated to helping businesses
          like yours leverage the power of AI to streamline work, enhance
          efficiency, and drive growth
        </p>
        <p style={{ marginBottom: "20px", marginTop: "5px", fontSize: "20px" }}>
          Our team of experts is highly skilled in C#, one of the most robust
          and versatile programming languages. We use C#, known for its clear
          syntax, strong type checking, and interoperability with platforms like
          .NET, to build complex, efficient AI solutions. However, our
          proficiency extends beyond C# to a comprehensive range of tech stacks,
          ensuring that we can always select the ideal tools to meet your unique
          business needs.
        </p>

        <h2 style={{ fontSize: "25px" }}>
          Here are some of the services we offer:
        </h2>

        <h2
          style={{
            marginTop: "20px",
            fontSize: "25px",
            fontFamily: "Poppins,sans-serif",
          }}
        >
          AI Strategy and Planning
        </h2>
        <p style={{ marginBottom: "20px", marginTop: "5px", fontSize: "20px" }}>
          We work with you to identify opportunities for AI integration, define
          a roadmap, and develop a strategy tailored to your business. Our goal
          is to help you unlock the true potential of AI in the most effective
          way possible.
        </p>

        <h2 style={{ fontSize: "25px", fontFamily: "Poppins,sans-serif" }}>
          GPT-4 Development
        </h2>
        <p style={{ marginBottom: "20px", marginTop: "5px", fontSize: "20px" }}>
          We offer custom development of ChatGPT plugins and GPT-4 powered
          tools. These solutions are designed to meet your specific needs and
          requirements, whether you're looking to enhance customer interactions,
          automate routine tasks, or uncover new insights from your data.
        </p>

        <h2 style={{ fontSize: "25px", fontFamily: "Poppins,sans-serif" }}>
          AI Implementation
        </h2>
        <p style={{ marginBottom: "20px", marginTop: "5px", fontSize: "20px" }}>
          We're experts at integrating AI solutions into existing workflows. Our
          approach ensures seamless adoption with minimal disruption to your
          business operations. We believe that the transition to AI should be a
          smooth journey, not a hurdle.
        </p>

        <p
          style={{ marginBottom: "20px", marginTop: "30px", fontSize: "20px" }}
        >
          Whether you need an expert for a one-off customized project, a
          foundation model or a dedicated team for ongoing work, we have the
          resources you need—available on demand and tailored to your needs.
          Every business is unique, and that's why we take the time to
          understand your specific challenges and goals. We're committed to
          providing solutions that not only solve your immediate problems but
          also position your business for future success.
        </p>

        <p
          style={{ marginBottom: "20px", marginTop: "20px", fontSize: "20px" }}
        >
          If you are trying to decide which company to hire, you need to speak
          to us. We will help make your decision easier. Time is of the essence
          and we value yours and much as we value ours. Once you provide us some
          details about your project, we will provide you with details and/or
          options quickly. If you are looking for a company that will provide
          you with 100%, you have come to the right place
        </p>

        <div
          style={{
            marginBottom: "20px",
            marginTop: "20px",
            textAlign: "center",
            fontSize: "20px",
          }}
        >
          <p
            style={{
              fontWeight: "bolder",
              fontSize: "25px",
              marginTop: "50px",
            }}
          >
            Thank you for considering Noctem as your partner in AI.
          </p>
          <br />

          <p>
            We look forward to discussing your project soon. Please complete the
            questionnaire on your project details and we will get back to you
            with a proposal.
          </p>
        </div>

        <button
          className="submit_btn"
          style={{ fontSize: "18px" }}
          onClick={() => Navigate("/aiservicemodal")}
        >
          COMPLETE APPLICATION
        </button>
      </div>
    </div>
  );
}

export default AIService;
