import React from "react";
import "./Sales.css";
import { NavLink, useNavigate } from "react-router-dom";

function Sales() {
  const Navigate = useNavigate();
  return (
    <div className="bg-sales">
      <div className="t-container bg">
        <div
          style={{
            marginTop: 0,
            width: "100%",
            paddingBottom: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <NavLink to="/">
            <img
              className="logo_testing"
              src={require("../img/logo.png")}
              alt="logo"
            />
          </NavLink>
        </div>
        <div
          style={{
            margin: "50px auto",
            backgroundColor: "gray",
            fontWeight: "600",
            fontSize: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
            textAlign: "center",
            fontFamily: "Poppins, sans-serif",
            color: "white",
            maxWidth: "600px",
          }}
        >
          video
        </div>
        <h3
          style={{
            textAlign: "center",
            lineHeight: "30px",
            fontFamily: "Poppins,sans-serif",
          }}
        >
          <span
            style={{
              fontSize: "30px",
              marginBottom: "20px",
              fontWeight: "bold",
            }}
          >
            10X Your Income!
          </span>
          <br /> <br /> Transition into the Future: High-Demand AI Services
          Offer <br />
          <span style={{ fontWeight: "bold" }}>High Rewards!</span>
        </h3>
        <br />
        <br />
        <p style={{ fontSize: "18px" }}>
          Are you a top-performing B2B sales representative looking to take your
          career to the next level? Are you seeking an opportunity that not only
          rewards your skills but also provides you with commissions and the
          tools to excel further? Your search ends here!
        </p>
        <h3
          style={{
            padding: "20px 0px 10px 0px",
            fontFamily: "Poppins,sans-serif",
            fontSize: "20px",
          }}
        >
          Welcome to the Future of B2B Sales!
        </h3>
        <p style={{ fontSize: "18px" }}>
          We're offering an exclusive opportunity for B2B sales representatives
          to join our forward-thinking tech company. We're at the forefront of
          the AI revolution, and we're inviting you to be a part of it. This is
          not just a job; it's a chance to build your dream career and a strong
          6 or 7 figure income! The window to create this type of income will
          not be around forever, once AI is fully adopted the businesses in a
          dominant position will prosper. In addition, those B2B sales reps that
          assisted businesses to get into this stable position will also
          prosper. How? Well, if you are currently a B2B sales rep you already
          have a huge advantage, contacts that you can follow up on immediately.
          This would not work with most services. Businesses need to understand
          what is happening with technology, the necessities, the possibilities,
          what are the facts and what is fiction in technology and experts that
          will not only integrate their business but for the businesses that
          have clear ideas, creativity to fully transcend their business, we can
          assist them greatly. They will thank you and you will receive a big
          commission up front and the possibility of great future residual
          income. This is not something you can sit on, the opportunity will be
          gone if you don’t act now. We have three powerful and exciting ways to
          choose from:
        </p>
        <br />
        <br />
        <br />
        <h3 style={{ fontFamily: "Poppins,sans-serif", fontSize: "25px" }}>
          Your Choices:
        </h3>
        <br />
        <br />
        <h3
          style={{
            paddingBottom: "10px",
            fontFamily: "Poppins,sans-serif",
            fontSize: "20px",
          }}
        >
          1. Become a Sales Rep PBD (Professional Business Developer):
        </h3>
        <p style={{ fontSize: "18px" }}>
          Join our team and sell our cutting-edge AI services to businesses.
          With virtually no competition and every business being a good lead due
          to needing to be aligned with AI, you can decide your commission
          amount within reason. Given the minimal competition and the fact that
          every business is a potential lead due to their need for AI alignment,
          either for productivity or customer convenience, you'll never run out
          of prospects. In addition, you are given a wide range for pricing to
          decide your commission amount whether its $500 or $3500.
        </p>
        <br />
        <br />
        <h3
          style={{
            paddingBottom: "10px",
            fontFamily: "Poppins,sans-serif",
            fontSize: "20px",
          }}
        >
          2. Become a PRD (Professional Referral Developer):
        </h3>
        <p style={{ fontSize: "18px" }}>
          Continue to work your current sales position and still work with us by
          helping your current customers or sales leads with an appointment for
          AI integration. Earn solid commissions, which might be more than what
          you currently earn in commissions per sale. In addition, you will
          receive residuals as well.
        </p>
        <br />
        <br />
        <h3
          style={{
            fontFamily: "Poppins,sans-serif",
            fontSize: "20px",
            paddingBottom: "10px",
          }}
        >
          3. Become a Sales Manager, Director of Sales, or VP of Sales:
        </h3>
        <p style={{ fontSize: "18px" }}>
          Here's where it gets exciting! As a sales representative, you have a
          fair chance to rise through the ranks. When you apply, indicate on the
          application that you're interested in running for a chance to be a
          manager or executive sales position. We will provide you with a budget
          and at the end of a full calendar year, whoever grows the most
          equipped and profitable team, along with meeting other criteria, will
          win a $100,000 BONUS pay and an offer for a 6 or 7 figure salaried
          position as a manager or executive position. This truly is a great
          opportunity for any sales rep working for us!
        </p>
        <br />
        <br />
        <h3
          style={{
            fontFamily: "Poppins,sans-serif",
            fontSize: "20px",
            paddingBottom: "10px",
          }}
        >
          The Sky's the Limit!
        </h3>
        <p style={{ fontSize: "18px" }}>
          No matter where you start and end up with our company, your efforts
          will be duly rewarded with substantial commissions and aggressive
          residual income. Whether you decide to compete for a salaried
          executive position or work with us at a bare minimum as a PRD, you
          will elevate your earnings.
        </p>
        <br />
        <br />
        <h3
          style={{
            fontFamily: "Poppins,sans-serif",
            fontSize: "20px",
            paddingBottom: "10px",
          }}
        >
          The Bottom Line
        </h3>
        <p style={{ fontSize: "18px" }}>
          This opportunity presents a platform to advance your career, enhance
          your earnings, broaden your knowledge, and associate with motivated
          individuals who share your mindset.
          <br />
          <br />
          Undoubtedly, all these aspects are significant, but the paramount
          factor is the security you will be establishing for yourself and your
          loved ones. This path is the simplest and safest way to actualize all
          your dreams and prepare for the inevitable significant changes that
          are bound to occur within the next couple of years.
        </p>
        <br />
        <br />
        <p
          style={{
            textAlign: "center",
            padding: "10px 0px",
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: "Poppins,sans-serif",
          }}
        >
          Join a ground floor opportunity with needed services in a time when
          joining is still very early, so timing could not be better.
        </p>
        <br />
        <br />
        <p style={{ fontSize: "18px" }}>
          To get started, all you need to do is fill out the application at the
          end of this page. If selected, we will notify you. So why wait? Start
          your journey towards a brighter future today!
        </p>
        <br />
        <br />
        <p style={{ fontSize: "18px" }}>
          Provide a detailed picture of how the future will look, many positions
          will be replaced with AI automation, bots and services. Yes, sales
          reps can be replaced as well, the future landscape of marketing and
          sales is changing and you can prepare yourself and put yourself in a
          favorable position to profit from it or be fearful, unsure of where it
          will take you. We have the skills, tools and services to put you in
          the right position. Take action and direct your future. Submit your
          application and find out now.
        </p>
        <div
          style={{
            margin: "50px auto",
            backgroundColor: "gray",
            color: "white",
            fontWeight: "600",
            fontSize: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
            fontFamily: "Poppins,sans-serif",
            textAlign: "center",
            maxWidth: "600px",
          }}
        >
          video
        </div>
        <button
          className="submit_btn"
          style={{ fontSize: "15px" }}
          onClick={() => Navigate("/salesmodal")}
        >
          COMPLETE APPLICATION
        </button>
      </div>
    </div>
  );
}

export default Sales;
