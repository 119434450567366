import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import AIServiceModalComponent from "../utils/AIServiceModalComponent";

function AIServiceModal() {
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-sales">
      <div className="t-container">
        <div
          style={{
            marginTop: 0,
            width: "100%",
            paddingBottom: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <NavLink to="/">
            <img
              className="logo_testing"
              src={require("../img/logo.png")}
              alt="logo"
            />
          </NavLink>
        </div>

        <h1
          style={{
            textAlign: "center",
            marginTop: "20px",
            fontFamily: "Poppins,sans-serif",
          }}
        >
          AI/AGI Services Questionnaire:
        </h1>

        <div
          style={{
            fontFamily: "Poppins,sans-serif",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              paddingBottom: "10px",
              marginTop: "20px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <label style={{ width: "300px", fontFamily: "Poppins,sans-serif" }}>
              Is This A New Project?
            </label>
            <input type="text" />
          </div>

          <div
            style={{
              paddingBottom: "10px",
              marginTop: "20px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <label style={{ width: "300px", fontFamily: "Poppins,sans-serif" }}>
              What Is The Budget For This Project?
            </label>
            <input type="text" />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label
            style={{ marginBottom: "10px", fontFamily: "Poppins,sans-serif" }}
          >
            Describe Of What You Are Attempting To Accomplish: (Or Attach PDF)
          </label>
          <textarea
            id="additional-info"
            style={{ marginBottom: "20px", height: "150px" }}
          ></textarea>
        </div>

        <h3 style={{ marginTop: "30px", fontFamily: "Poppins,sans-serif" }}>
          Who Is The Person To Speak To?
        </h3>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            fontFamily: "Poppins,sans-serif",
          }}
        >
          <div
            style={{
              paddingBottom: "10px",
              marginTop: "20px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <label style={{ width: "300px" }}>Name:</label>
            <input type="text" />
          </div>

          <div
            style={{
              paddingBottom: "10px",
              marginTop: "20px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <label style={{ width: "300px" }}>Number/Ext:</label>
            <input type="text" />
          </div>

          <div
            style={{
              paddingBottom: "10px",
              marginTop: "20px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <label style={{ width: "300px" }}>Email:</label>
            <input type="text" />
          </div>

          <div
            style={{
              paddingBottom: "10px",
              marginTop: "20px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <label style={{ width: "300px" }}>Best Times To Reach You:</label>
            <input type="text" />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "30px",
          }}
        >
          <label
            style={{ marginBottom: "10px", fontFamily: "Poppins,sans-serif" }}
          >
            Any Additional Details We Need To Know About:
          </label>
          <textarea
            id="additional-info"
            style={{
              marginBottom: "20px",
              height: "150px",
              fontFamily: "Poppins,sans-serif",
            }}
          ></textarea>
        </div>

        <button
          style={{ marginTop: "20px" }}
          className="submit_btn"
          onClick={handleModalOpen}
        >
          SUBMIT
        </button>
        {modalVisible && <AIServiceModalComponent onClose={handleModalClose} />}
      </div>
    </div>
  );
}

export default AIServiceModal;
