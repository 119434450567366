import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Main from "../../Main";
import "./Home.css";

const Home = () => {
  const [startAnimate, setStartAnimate] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setStartAnimate(true);
    }, 2800);
  }, []);


  return (
    <>
      <Header />
      <Main startAnimate={startAnimate} />
    </>
  );
};

export default Home;
