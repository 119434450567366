import React from "react";
import "./ModalComponent.css";

function SalesModalComponent({ onClose }) {
  return (
    <div
      className="modal-overlay"
      style={{ backgroundColor: "black", opacity: "0.9" }}
    >
      <div
        className="modal-content"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        <img
          style={{ width: "150px", height: "auto" }}
          className="modal-logo"
          src={require("../img/logo.png")}
          alt="logo"
        />
        <p>
          Thank you for submitting your information for potential employment
          with our company. If you do not hear back from us right away that may
          not mean we are not interested. We have many applicants information to
          go through and at times we file away your resume for later review. If
          we feel you are a good fit right away and still have an opening we
          will reach out to you as soon as possible. Please check your spam or
          junk folder. Thank you again for your interest.
        </p>
      </div>
    </div>
  );
}

export default SalesModalComponent;
