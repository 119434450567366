import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

function PrdSales() {
  const Navigate = useNavigate();

  return (
    <div className="bg-sales">
      <div className="t-container bg">
        <div
          style={{
            marginTop: 0,
            width: "100%",
            paddingBottom: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <NavLink to="/">
            <img
              className="logo_testing"
              src={require("../img/logo.png")}
              alt="logo"
            />
          </NavLink>
        </div>

        <div
          style={{
            margin: "50px auto",
            backgroundColor: "gray",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
            fontFamily: "Poppins, sans-serif",
            fontSize: "25px",
            fontWeight: "600",
            textAlign: "center",
            maxWidth: "600px",
          }}
        >
          video
        </div>

        <h1
          style={{
            textAlign: "center",
            fontSize: "30px",
            fontFamily: "Poppins,sans-serif",
          }}
        >
          Amplify Your Earnings with AI Services
        </h1>

        <h2
          style={{
            fontSize: "25px",
            fontFamily: "Poppins,sans-serif",
          }}
        >
          Embrace the Future
        </h2>
        <p style={{ marginBottom: "20px", marginTop: "5px", fontSize: "20px" }}>
          Are you a seasoned sales professional? Imagine if you could
          supercharge your career and earnings. With our company, you can
          maintain your current sales position while adding a lucrative second
          income stream.
        </p>

        <h2
          style={{
            fontSize: "25px",

            fontFamily: "Poppins,sans-serif",
          }}
        >
          Harness the Power of AI
        </h2>
        <p style={{ marginBottom: "20px", marginTop: "5px", fontSize: "20px" }}>
          We’re at the cutting edge of the AI revolution, and we’re inviting you
          to join us. As a Professional Referral Developer (PRD), you stand to
          earn substantial commissions, potentially adding $4K - $10K to your
          monthly income.
        </p>

        <h2 style={{ fontSize: "25px", fontFamily: "Poppins,sans-serif" }}>
          Build a Legacy
        </h2>
        <p style={{ marginBottom: "20px", marginTop: "5px", fontSize: "20px" }}>
          But the benefits don’t stop at immediate commissions. You’re also
          building a lasting legacy. Your referrals will generate a robust
          residual income that will benefit you for years to come. Whether you
          close a sale or not, there’s an opportunity for you to earn additional
          commissions
        </p>

        <h2 style={{ fontSize: "25px" }}>Complete Support</h2>
        <p style={{ marginBottom: "20px", marginTop: "5px", fontSize: "20px" }}>
          We equip you with all the tools you need to succeed – forms, questions
          and a clear action plan for every scenario. All you need to do is
          follow our simple steps and we’ll handle the rest.
        </p>

        <h2 style={{ fontSize: "25px", fontFamily: "Poppins,sans-serif" }}>
          Commission Structure
        </h2>
        <p style={{ marginBottom: "20px", marginTop: "5px", fontSize: "20px" }}>
          Our commission structure is designed to reward your efforts
          generously. For sales of $5,000 and under, you’ll earn a minimum of
          $200. Sales between $5,001 and $25,000 will earn you between $201 and
          $500. For sales between $25,001 and $50,000, your commission will be
          between $501 and $800. And for sales of $50,001 and above, you’ll earn
          a minimum of $850.
        </p>

        <h2 style={{ fontSize: "25px", fontFamily: "Poppins,sans-serif" }}>
          The Future is Bright
        </h2>
        <p style={{ marginBottom: "20px", marginTop: "5px", fontSize: "20px" }}>
          The future holds even more promise. We’re gearing up to launch a range
          of new services and products that will significantly assist boosting
          your earnings. This is truly a win-win situation that will enrich your
          career in more ways than one.
        </p>

        <h2 style={{ fontSize: "25px", fontFamily: "Poppins,sans-serif" }}>
          Take the Leap
        </h2>
        <p style={{ marginBottom: "20px", marginTop: "5px", fontSize: "20px" }}>
          Ready to leap into the future? Fill out the form to join our next
          training session. Embark on your journey towards a prosperous future
          today!
        </p>

        <div
          style={{
            margin: "50px auto",
            backgroundColor: "gray",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
            textAlign: "center",
            fontSize: "30px",
            fontFamily: "Poppins,sans-serif",

            maxWidth: "600px",
          }}
        >
          video
        </div>

        <button
          style={{ fontSize: "18px" }}
          className="submit_btn"
          onClick={() => Navigate("/prdsalesmodal")}
        >
          COMPLETE APPLICATION
        </button>
      </div>
    </div>
  );
}

export default PrdSales;
