import React from "react";
import "./DevopsModalComponent.css";

function DevelopmentModalComponent({ onClose }) {
  return (
    <div
      className="modal-overlay"
      style={{ backgroundColor: "black", opacity: "0.9" }}
    >
      <div
        className="modal-content"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        <img
          style={{ width: "150px", height: "auto" }}
          className="modal-logo"
          src={require("../img/logo.png")}
          alt="logo"
        />
        <p>
          <span
            style={{
              fontSize: "21px",
              fontWeight: "bold",
            }}
          >
            Thank you for joining the wait list.
          </span>{" "}
          <br />
          You will be notified by email when you have reached the top of the
          list to begin using Project Evolv powerful tool set! Thank you again
          for becoming part of the Project Evolv community. You can keep up date
          with the progress on our LinkedIn showcase page for Project Evolv.
        </p>
      </div>
    </div>
  );
}

export default DevelopmentModalComponent;
