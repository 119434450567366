import React from "react";
import "./ModalComponent.css";

function VirtualEventModal({ onClose }) {
  return (
    <div
      className="modal-overlay"
      style={{ backgroundColor: "black", opacity: "0.9" }}
    >
      <div
        className="modal-content"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        <img
          style={{ width: "150px", height: "auto" }}
          className="modal-logo"
          src={require("../img/logo.png")}
          alt="logo"
        />
        <p style={{ fontSize: "20px" }}>
          <span
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              paddingBottom: "10px",
              fontFamily: "Poppins,sans-serif",
            }}
          >
            Thank you for your interest in our virtual event.
          </span>
          <br />
          You should receive all the information you need to join us for the
          event by email. Please check your spam or junk folder.
        </p>
      </div>
    </div>
  );
}

export default VirtualEventModal;
