import React, { useState, useContext, useEffect, useRef } from "react";
import TextScramble from "@twistezo/react-text-scramble/lib/TextScramble";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { store } from "../App";
import "./Navbar.css";
import logo from "../img/logo.png";

const languages: { [key: string]: any } = {
  English: "en",
  Spanish: "sp",
};

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [headerScrambled, setHeaderScrambled] = useState<boolean>(false);
  const { language: lang, setLanguage } = useContext(store);
  const [click, setClick] = useState<boolean>(false);
  const navigate = useNavigate();
	const location = useLocation();

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {
    setNavbar(false);
    setClick(false);
  }

  useEffect(() => {
    setTimeout(() => {
      setHeaderScrambled(true);
    }, 1000);
  }, []);
 
  const scrambleText1 = [t("services")];
  const scrambleText2 = [t("employment")];
  const scrambleText3 = [t("investor")];
  const scrambleText5 = [t("contactUs")];

  const [navbar, setNavbar] = useState(false);
  //const user = props.User.userEmail;

  return (
    <>
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <div>
            <img className="logo" src={logo} alt="logo" />
          </div>
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <a
              href=".#"
              className="nav-links"
              onClick={(e) => {
                e.preventDefault();
                setNavbar(false);

                if (location.pathname === "" ||
                location.pathname === '/' || location.pathname === "/#/"){
                  document
                  .getElementById("service")!
                  .scrollIntoView({ behavior: "smooth", block: "start" });
               }
               else
                navigate("/");
              }}
            >
              {headerScrambled ? (
                t("services")
              ) : (
                <TextScramble
                  texts={scrambleText1}
                  letterSpeed={50}
                  nextLetterSpeed={100}
                  pauseTime={500}
                />
              )}
            </a>
          </li>

          <li className="nav-item">
            <Link to="/employment" className="nav-links" onClick={closeMobileMenu}>
              
            {headerScrambled ? (
              t("employment")
            ) : (
              <TextScramble
                texts={scrambleText2}
                letterSpeed={50}
                nextLetterSpeed={100}
                pauseTime={500}
              />
            )}
            </Link>
          </li>

          <li className="nav-item">
            <a
              href=".#"
              className="nav-links"
              onClick={(e) => {
                e.preventDefault();
                setNavbar(false);

                if (location.pathname === "" ||
                location.pathname === '/' || location.pathname === "/#/"){
                  var element = document.getElementById("investor")!;
                  var headerOffset = 75;
                  var elementPosition = element.getBoundingClientRect().top;
                  var offsetPosition =
                    elementPosition + window.pageYOffset - headerOffset;
                  window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                  });
               }
               else
                navigate("/");
              }}
            >
              {headerScrambled ? (
                t("investor")
              ) : (
                <TextScramble
                  texts={scrambleText3}
                  letterSpeed={50}
                  nextLetterSpeed={100}
                  pauseTime={500}
                />
              )}
            </a>
          </li>

          <li className="nav-item">
          <select
              id="langselector"
              title="langselector"
              className="nav-links"
              value={lang}
              onChange={(e) => {
                setLanguage(e.target.value);
                i18n.changeLanguage(languages[e.target.value]);
                setNavbar(false);
              }}
            >
              <option value="English" id="en" className="translate">
                English
              </option>
              <option value="Spanish" id="sp" className="translate">
                Spanish
              </option>
            </select>
          </li>

          <li className="nav-item">
            <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
            {headerScrambled ? (
              t("contactUs")
            ) : (
              <TextScramble
                texts={scrambleText5}
                letterSpeed={50}
                nextLetterSpeed={100}
                pauseTime={500}
              />
            )}
            </Link>
          </li>
        </ul>
      </div>
    </nav>
    </>
  );
}

export default Navbar;
