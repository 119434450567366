import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import DevelopmentModalComponent from "../utils/DevelopmentModalComponent";

function DevelopmentModal() {
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="t-ultimate-container tcontainer"
      style={{ fontFamily: "Poppins,sans-serif" }}
    >
      <div className="t-container">
        <div
          style={{
            marginTop: 0,
            width: "100%",
            paddingBottom: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <NavLink to="/">
            <img
              className="logo_testing"
              src={require("../img/logo.png")}
              alt="logo"
            />
          </NavLink>
          <h1
            style={{
              paddingTop: "50px",
              fontSize: "30px",
              marginTop: "20px",
              fontFamily: "Poppins,sans-serif",
            }}
          >
            Project Evolv Waitlist Form:
          </h1>
        </div>
      </div>
      <div className="container">
        <h1>Personal Information:</h1>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "750px",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "170px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "300px",
            }}
          >
            <label for="name">Name:</label>
            <input type="text" id="name" />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "300px",
            }}
          >
            <label for="email">Email:</label>
            <input type="text" id="email" />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "300px",
            }}
          >
            <label for="number">Contact No:</label>
            <input type="number" id="number" />
          </div>
        </div>
        <h1 style={{ marginTop: "15px" }}>Professional Information:</h1>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "750px",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "170px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "300px",
            }}
          >
            <label for="name">Company Name:</label>
            <input type="text" id="name" />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "300px",
            }}
          >
            <label for="email">Industry:</label>
            <input type="text" id="email" />
          </div>

          <div
            style={{
              display: "flex",
              maxWidth: "300px",
              flexDirection: "column",
            }}
          >
            <label for="number">Developer Team Member Size:</label>
            <input type="number" id="number" />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "300px",
            }}
          >
            <label for="number">Job Title/Role:</label>
            <input type="number" id="number" />
          </div>
        </div>

        <h1>Development Experience:</h1>

        <ul style={{ paddingLeft: "18px" }}>
          <li style={{ paddingBottom: "10px" }}>
            Years of C# Development Experience:
          </li>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              maxWidth: "300px",
              height: "100px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                name="1"
                style={{ width: "auto", marginRight: "5px" }}
              />
              <label for="1">Less than 1 Year:</label>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                name="1"
                style={{ width: "auto", marginRight: "5px" }}
              />
              <label for="1">1-3 Years:</label>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                name="1"
                style={{ width: "auto", marginRight: "5px" }}
              />
              <label for="1">4-6 years:</label>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                name="1"
                style={{ width: "auto", marginRight: "5px" }}
              />
              <label for="1">7-10 years:</label>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                name="1"
                style={{ width: "auto", marginRight: "5px" }}
              />
              <label for="1">More Than 10 years</label>
            </div>
          </div>
        </ul>

        <ul style={{ paddingLeft: "18px", paddingTop: "20px" }}>
          <li style={{ paddingBottom: "10px" }}>
            Which development platforms do you primarily work on?
          </li>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              maxWidth: "300px",
              height: "50px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                name="1"
                style={{ width: "auto", marginRight: "5px" }}
              />
              <label for="1">.NET 5</label>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                name="1"
                style={{ width: "auto", marginRight: "5px" }}
              />
              <label for="1">.NET Core</label>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                name="1"
                style={{ width: "auto", marginRight: "5px" }}
              />
              <label for="1">WPF</label>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                name="1"
                style={{ width: "auto", marginRight: "5px" }}
              />
              <label for="1">.NET MAUI</label>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                name="1"
                style={{ width: "auto", marginRight: "5px" }}
              />
              <label for="1">Xamarin</label>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                name="1"
                style={{ width: "auto", marginRight: "5px" }}
              />
              <label for="1">PRISM</label>
            </div>
          </div>
          <h2 style={{ paddingBottom: "10px" }}>
            Others (Please specify):_________________
          </h2>
          <textarea
            id="additional-info"
            style={{ marginBottom: "20px", height: "150px" }}
          ></textarea>
        </ul>

        <h1>Interest in Project Evolv:</h1>
        <ul style={{ paddingLeft: "18px" }}>
          <li style={{ paddingBottom: "10px" }}>
            What specific features of Project Evolv are you most interested in?
          </li>
          <textarea
            id="additional-info"
            style={{ marginBottom: "20px", height: "100px" }}
          ></textarea>

          <li style={{ paddingBottom: "10px" }}>
            How do you anticipate Project Evolv will assist in your development
            work?
          </li>
          <textarea
            id="additional-info"
            style={{ marginBottom: "20px", height: "100px" }}
          ></textarea>

          <li style={{ paddingBottom: "10px" }}>
            Would you be interested in having training or consulting as part of
            the service?
          </li>
          <textarea
            id="additional-info"
            style={{ marginBottom: "20px", height: "100px" }}
          ></textarea>

          <li style={{ paddingBottom: "10px" }}>
            List any specific needs you feel you may need from these tools?
          </li>
          <textarea
            id="additional-info"
            style={{ marginBottom: "20px", height: "100px" }}
          ></textarea>
        </ul>

        <h1>Preferred Communication Method:</h1>
        <p>
          We will be providing a site link or social media link to receive
          updates however, if we offer personalized updates for your particular
          level of service, please indicate how would you like to receive
          updates about Project Evolv?
        </p>

        <div
          style={{
            display: "flex",
            paddingTop: "20px",
            justifyContent: "flex-start",
            alignItems: "start",
          }}
        >
          <div>
            <input
              type="checkbox"
              name="1"
              style={{ width: "auto", marginRight: "5px" }}
            />

            <label for="1" style={{ width: "auto", marginRight: "10px" }}>
              Email
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              name="1"
              style={{ width: "auto", marginRight: "5px" }}
            />
            <label for="1" style={{ width: "auto", marginRight: "10px" }}>
              Phone Call
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              name="1"
              style={{ width: "auto", marginRight: "5px" }}
            />
            <label for="1">Text Message</label>
          </div>
        </div>

        <ul>
          <h2 style={{ paddingBottom: "10px" }}>Others (Please specify):</h2>
          <textarea
            id="additional-info"
            style={{ marginBottom: "20px", height: "150px" }}
          ></textarea>
        </ul>

        <input
          type="checkbox"
          name="1"
          style={{ width: "auto", marginRight: "5px" }}
        />
        <p style={{ display: "inline" }}>
          By submitting this form, I agree to be contacted by Noctem Corp about
          Project Evolv and other related services. I understand my information
          will be used in accordance with Noctem’s privacy policy.
        </p>

        <button
          style={{ marginTop: "20px" }}
          className="submit_btn"
          onClick={handleModalOpen}
        >
          Submit
        </button>
      </div>

      {modalVisible && <DevelopmentModalComponent onClose={handleModalClose} />}
    </div>
  );
}

export default DevelopmentModal;
