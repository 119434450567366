import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import VirtualEventModal from "../utils/VirtualEventModal";

function VirtualEvent() {
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-sales">
      <div className="t-container bg">
        <div
          style={{
            marginTop: 0,
            width: "100%",
            paddingBottom: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <NavLink to="/">
            <img
              className="logo_testing"
              src={require("../img/logo.png")}
              alt="logo"
            />
          </NavLink>
        </div>

        <h2
          className="t_head1"
          style={{
            textAlign: "center",
            marginBottom: "5px",
            paddingBottom: "5px",
            color: "white",
            fontFamily: "Poppins, sans-serif",
            textShadow: "1px 1px 1px gray",
          }}
        >
          Thank you for your interest in our virtual event.
          <br />
          <span style={{ fontSize: "20px" }}>
            Please complete questionnaire completely so we can provide you with
            forms and details to participate in the event.
          </span>
        </h2>

        <div
          style={{
            fontFamily: "Poppins,sans-serif",
            display: "flex",
            flexDirection: "row",
            margin: "50px 0px",
            flexWrap: "wrap",
            maxWidth: "980px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              paddingBottom: "10px",
              paddingRight: "10px",
              maxWidth: "250px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label style={{ paddingBottom: "5px" }}>Name:</label>
            <input type="text" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "10px",
              maxWidth: "250px",
              paddingRight: "10px",
            }}
          >
            <label style={{ paddingBottom: "5px" }}>Position:</label>
            <input type="text" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "10px",
              paddingRight: "10px",
              maxWidth: "250px",
            }}
          >
            <label style={{ paddingBottom: "5px" }}>Company Name:</label>
            <input type="text" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "20px",
              paddingRight: "10px",
              maxWidth: "250px",
            }}
          >
            <label style={{ paddingBottom: "5px" }}>Email:</label>
            <input type="text" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "20px",
              paddingRight: "10px",
              maxWidth: "250px",
            }}
          >
            <label style={{ paddingBottom: "5px" }}>Phone:</label>
            <input type="text" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "20px",
              paddingRight: "10px",
              maxWidth: "250px",
            }}
          >
            <label style={{ paddingBottom: "5px" }}>
              Best Time To Reach You:
            </label>
            <input type="text" />
          </div>
        </div>

        <h2 style={{ paddingBottom: "20px", fontSize: "25px" }}>
          Any Specifics or Questions You Have Specifically:
        </h2>

        <textarea
          id="additional-info"
          style={{ marginBottom: "20px", height: "150px" }}
        ></textarea>

        <h2 style={{ fontSize: "25px", paddingBottom: "20px" }}>
          Check any of the following:
        </h2>

        <div
          style={{
            marginBottom: "20px",
            marginLeft: "30px",
            marginTop: "10px",
          }}
        >
          <div style={{ display: "flex", padding: "5px" }}>
            <input type="checkbox" style={{ width: "auto" }} />
            <p style={{ marginLeft: "10px", fontSize: "20px" }}>
              Need to update an existing app or program
            </p>
          </div>
          <div style={{ display: "flex", padding: "5px" }}>
            <input style={{ width: "auto" }} type="checkbox" />{" "}
            <p style={{ marginLeft: "10px", fontSize: "20px" }}>
              Want to integrate your business with AI
            </p>
          </div>
          <div style={{ display: "flex", padding: "5px" }}>
            <input style={{ width: "auto" }} type="checkbox" />{" "}
            <p style={{ marginLeft: "10px", fontSize: "20px" }}>
              Have an ambitious project
            </p>
          </div>
          <div style={{ display: "flex", padding: "5px" }}>
            <input style={{ width: "auto" }} type="checkbox" />{" "}
            <p style={{ marginLeft: "10px", fontSize: "20px" }}>
              Already working with a service for building or development
              project/s
            </p>
          </div>
        </div>

        <h2
          style={{
            paddingBottom: "20px",
            paddingTop: "20px",
            fontSize: "25px",
          }}
        >
          List any specifics for any service you checked above:
        </h2>

        <textarea
          id="additional-info"
          style={{ marginBottom: "20px", height: "150px" }}
        ></textarea>

        <button
          style={{ marginTop: "20px" }}
          className="submit_btn"
          onClick={handleModalOpen}
        >
          SUBMIT
        </button>
        {modalVisible && <VirtualEventModal onClose={handleModalClose} />}
      </div>
    </div>
  );
}

export default VirtualEvent;
