import React, { createContext, useState, useEffect } from "react";
import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/page/home/Home";
import { useTranslation } from "react-i18next";
import Employment from "./components/Employment";
import TestingService1 from "./components/page/TestingService1";
import DevOpsService2 from "./components/page/DevOpsService2";
import DevelopmentService3 from "./components/page/DevelopmentService3";
import TestingModal from "./components/page/TestingModal";
import Contact from "./components/page/Contact";
import DevopsModal from "./components/page/DevopsModal";
import { theme } from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { Paper } from "@mui/material";
import DevelopmentModal from "./components/DevelopmentModal";
import Sales from "./components/Sales";
import PrdSales from "./components/PrdSales";
import AIService from "./components/AIService";
import SalesModal from "./components/SalesModal";
import PrdSalesModal from "./components/PrdSalesModal";
import AIServiceModal from "./components/AIServiceModal";
import VirtualEvent from "./components/VirtualEvent";

const Context = createContext<{ language: string; setLanguage: Function }>({
  language: "English",
  setLanguage: () => {},
});
export const store = Context;

const languages: { [key: string]: any } = {
  English: "en",
  Spanish: "sp",
};

function App() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<string>("English");
  useEffect(() => {
    let currentLang = localStorage.getItem("language");
    if (currentLang) {
      setLanguage(currentLang ? currentLang : "");
      i18n.changeLanguage(languages[currentLang]);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Paper>
          <div className="App">
            <Context.Provider value={{ language, setLanguage }}>
              <Router basename="/">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/employment" element={<Employment />} />
                  <Route path="/testing" element={<TestingService1 />} />
                  <Route path="/testingmodal" element={<TestingModal />} />
                  <Route path="/devops" element={<DevOpsService2 />} />
                  <Route path="/devopsmodal" element={<DevopsModal />} />
                  <Route path="/development" element={<DevelopmentService3 />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/developmentmodal" element={<DevelopmentModal />} />
                  <Route path="/sales" element={<Sales />} />
                  <Route path="/prdsales" element={<PrdSales />} />
                  <Route path="/aiservice" element={<AIService />} />
                  <Route path="/salesmodal" element={<SalesModal />} />
                  <Route path="/prdsalesmodal" element={<PrdSalesModal />} />
                  <Route path="/aiservicemodal" element={<AIServiceModal />} />
                  <Route path="/virtualevent" element={<VirtualEvent />} />
                </Routes>
                <Navbar />
              </Router>
            </Context.Provider>
          </div>
        </Paper>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
