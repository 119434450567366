import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import DevopsModalComponent from "../../utils/DevopsModalComponent";

function DevopsModal() {
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="t-ultimate-container tcontainer">
      <div className="t-container">
        <div
          style={{
            marginTop: 0,
            width: "100%",
            paddingBottom: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <NavLink to="/">
            <img
              className="logo_testing"
              src={require("../../img/logo.png")}
              alt="logo"
            />
          </NavLink>
        </div>
      </div>
      <div className="container">
        <h1>CI/CD Questionnaire:</h1>

        <div className="input-group">
          <label for="name">
            Do you currently have any CI/CD pipeline in place?
          </label>
          <input type="text" id="name" />
        </div>

        <div className="input-group">
          <label for="email">Current tech stack you are using? </label>
          <input type="email" id="email" />
        </div>

        <h2 style={{ paddingBottom: "20px" }}>
          Description of what you are attempting to accomplish: (or attach PDF)
        </h2>

        <textarea id="additional-info" style={{ height: "150px" }}></textarea>

        <h2 style={{ paddingBottom: "20px" }}>
          Who is the person to reach & send quote to?
        </h2>

        <div className="input-group2">
          <label for="age">Name:</label>
          <input type="number" id="age" />
        </div>

        <div className="input-group2">
          <label for="gender">Number/Ext.:</label>
          <input type="text" id="gender" />
        </div>

        <div className="input-group2">
          <label for="occupation">Email:</label>
          <input type="text" id="occupation" />
        </div>

        <div className="input-group2">
          <label for="interests">Best times to reach:</label>
          <input type="text" id="interests" />
        </div>

        <h2 style={{ paddingBottom: "20px" }}>
          Any Additional Details We Need To Know About:
        </h2>

        <textarea
          id="additional-info"
          style={{ marginBottom: "20px", height: "150px" }}
        ></textarea>

        <button className="submit_btn" onClick={handleModalOpen}>
          Submit
        </button>
      </div>

      {modalVisible && <DevopsModalComponent onClose={handleModalClose} />}
    </div>
  );
}

export default DevopsModal;
