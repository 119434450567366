import React, { useRef, useState, useEffect } from "react";
import "./Contact.css";
import { useTranslation } from "react-i18next";
import emailjs from "emailjs-com";
import { ClipLoader } from "react-spinners";
import { NavLink } from "react-router-dom";

function Contact() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState<any>(false);

  const formRef = useRef<HTMLFormElement>(null);
  const [done, setDone] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (formRef.current) {
      emailjs
        .sendForm(
          process.env.REACT_APP_PRIVATE_KEY_FIRST as string,
          process.env.REACT_APP_PRIVATE_KEY_SECOND as string,
          formRef.current,
          process.env.REACT_APP_PRIVATE_KEY_THIRD as string
        )
        .then(
          (result) => {
            console.log(result.text);
            setDone(true);
            setLoading(false);
            setTimeout(() => {
              setDone(false);
            }, 3000);
          },
          (error) => {
            setLoading(true);
            console.log(error.text);
          }
        );

      formRef.current.user_name.value = "";
      formRef.current.user_phone.value = "";
      formRef.current.user_email.value = "";
      formRef.current.user_subject.value = "";
      formRef.current.user_message.value = "";
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-container">
      {done && (
        <div
          className="alert"
          style={{ zIndex: 999, position: "fixed", top: 0, width: "100vw" }}
        >
          <div className="alert-content">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
            </svg>
            <p>Great! Will start conversation soon!</p>
          </div>
        </div>
      )}
      <section className="investors" id="investor">
        <div
          className="employment"
          style={{
            paddingTop: "1px",
            marginTop: 0,
            paddingBottom: "40px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
        <div className="radial_circle"></div>
        <div className="radial_circle4"></div>
        <div className="radial_circle5"></div>
          <h2
            className="text_uppercase mobile_responsive_text helvetica"
            style={{
              fontWeight: "bold",
              paddingBottom: 5,
              marginBottom: 0,
              paddingTop: 55,
              color: "white",
              textShadow: "0 0 10px rgba(255, 255, 255, 1)",
            }}
          >
            Contact Us
          </h2>
          <p style={{ color: "white", opacity: 0.9 }}>{t("recevie_d")}</p>
        </div>
        <form
          className="contact_form"
          ref={formRef}
          onSubmit={handleSubmit}
          style={{ width: "80%", paddingTop: "0px", marginTop: "0px" }}
        >
          <input
            required
            style={{ width: "100%" }}
            type="text"
            name="user_name"
            className="name_input"
            placeholder={t("*Name")}
          />
          <input
            style={{ width: "100%" }}
            type="text"
            className="phone_input"
            name="user_phone"
            placeholder={t("Phone")}
          />
          <input
            type="text"
            style={{ width: "100%" }}
            name="user_subject"
            placeholder={t("Subject")}
          />
          <input
            required
            type="email"
            name="user_email"
            placeholder={t("*Email")}
          />
          <textarea
            required
            placeholder={t("*Message...")}
            rows={6}
            name="user_message"
          ></textarea>
          <button className="submit_btn text_uppercase" type="submit">
            {loading ? (
              <ClipLoader color="#ffffff" size={15} loading={loading} />
            ) : (
              t("submit_btn")
            )}
          </button>
        </form>
      </section>
    </div>
  );
}

export default Contact;
