import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

function Employment() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section
      className="employment"
      id="employment"
      style={{
        paddingTop: "30px",
        marginTop: 0,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div className="radial_circle"></div>
      <div className="radial_circle4"></div>
      <div className="radial_circle5"></div>
      <h2
        className="text_uppercase mobile_responsive_text helvetica"
        style={{
          fontWeight: "bold",
          paddingBottom: 5,
          marginBottom: 0,
          paddingTop: 55,
          color: "white",
          textShadow: "0 0 10px rgba(255, 255, 255, 1)",
        }}
      >
        {t("employ")}
      </h2>

      <div
        className="employment_flex"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "25px",
          alignItems: "center",
        }}
      >
        <div
          className="employment_card empc-0"
          style={{
            marginBottom: "1rem",
            width: "80vw",
            height: "100%",
            marginRight: 0,
            paddingLeft: "30px",
          }}
        >
          <h4 className="helvetica text_uppercase">{t("employ_head1")}</h4>
          <div className="qualification_text">
            <p className="qualification_details" style={{ maxWidth: "95%" }}>
              <span>{t("qualifications1")}</span>
              {t("quali_desc1")}
            </p>
          </div>
          <div className="job_type">
            <span>{t("jobType1")}</span>
            {t("jobDesc1")}
          </div>
          <div className="salary" style={{ paddingBottom: "70px" }}>
            <span>{t("salary1")}</span>
            {t("salDesc1")}
          </div>
          <button
            className="apply_btn btn-gradient employ_btn_1"
            style={{
              marginLeft: 0,
              fontSize: "18px",
            }}
          >
            {t("apply_btn")}
          </button>
        </div>
        <div
          className="employment_card empc-1"
          style={{
            marginBottom: "1rem",
            width: "80vw",
            height: "100%",
            marginRight: 0,
            paddingLeft: "30px",
          }}
        >
          <h4 className="helvetica text_uppercase">{t("employ_head2")}</h4>
          <div className="qualification_text">
            <p className="qualification_details">
              <span>{t("qualifications2")}</span>
              {t("quali_desc2")}
            </p>

            <p className="qualification_details">
              <span>{t("madit")} </span>
              {t("madit01")}
            </p>
            <p className="qualification_details">
              <span>{t("preff")} </span>
              {t("preff01")}
            </p>
          </div>
          <div className="job_type">
            <span>{t("jobType2")}</span>
            {t("jobDesc2")}
          </div>
          <div className="salary" style={{ paddingBottom: "70px" }}>
            <span>{t("salary2")}</span>
            {t("salDesc2")}
          </div>
          <button
            className="apply_btn btn-gradient employ_btn_2"
            style={{
              marginLeft: 0,
              fontSize: "18px",
            }}
          >
            {t("apply_btn")}
          </button>
        </div>
        <div
          className="employment_card empc-2"
          style={{
            marginBottom: "1rem",
            width: "80vw",
            height: "100%",
            marginRight: 0,
            paddingLeft: "30px",
          }}
        >
          <h4 className="helvetica text_uppercase">{t("employ_head3")}</h4>
          <div className="qualification_text">
            <p className="qualification_details">{t("quali_desc3")}</p>
          </div>
          <div className="job_type">
            <span>{t("jobType3")}</span>
            {t("jobDesc3")}
          </div>
          <div className="salary" style={{ paddingBottom: "70px" }}>
            <span>{t("salary3")}</span>
            {t("salDesc3")}
          </div>
          <button
            className="apply_btn btn-gradient employ_btn_3"
            style={{
              marginLeft: 0,
              fontSize: "18px",
            }}
          >
            {t("apply_btn")}
          </button>
        </div>
        <div
          className="employment_card empc-2"
          style={{
            marginBottom: "1rem",
            width: "80vw",
            height: "100%",
            marginRight: 0,
            paddingLeft: "30px",
          }}
        >
          <h4 className="helvetica text_uppercase">{t("employ_head3")}</h4>
          <div className="qualification_text">
            <p className="qualification_details">{t("quali_desc3")}</p>
          </div>
          <div className="job_type">
            <span>{t("jobType3")}</span>
            {t("jobDesc3")}
          </div>
          <div className="salary" style={{ paddingBottom: "70px" }}>
            <span>{t("salary3")}</span>
            {t("salDesc3")}
          </div>
          <button
            className="apply_btn btn-gradient employ_btn_3"
            style={{
              marginLeft: 0,
              fontSize: "18px",
            }}
          >
            {t("apply_btn")}
          </button>
        </div>
        <div
          className="employment_card empc-2"
          style={{
            marginBottom: "1rem",
            width: "80vw",
            height: "100%",
            marginRight: 0,
            paddingLeft: "30px",
          }}
        >
          <h4 className="helvetica text_uppercase">{t("employ_head3")}</h4>
          <div className="qualification_text">
            <p className="qualification_details">{t("quali_desc3")}</p>
          </div>
          <div className="job_type">
            <span>{t("jobType3")}</span>
            {t("jobDesc3")}
          </div>
          <div className="salary" style={{ paddingBottom: "70px" }}>
            <span>{t("salary3")}</span>
            {t("salDesc3")}
          </div>
          <button
            className="apply_btn btn-gradient employ_btn_3"
            style={{
              marginLeft: 0,
              fontSize: "18px",
            }}
          >
            {t("apply_btn")}
          </button>
        </div>
      </div>
    </section>
  );
}

export default Employment;
