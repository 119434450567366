import React, { useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from "react";
import CookiePopup from "./CookiePopup";
import "./Footer.css";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);

  // Show the button when the user has scrolled 300px down from the top
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Attach or remove the scroll event listener on mount/unmount
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  // Scroll to the top of the page when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer>
      <div className="radial_circle6"></div>
      <div className="radial_circle7"></div>
      <div className="container_footer">
        <img src={require("../img/footer.png")} alt="logo" width="150px" />
        <div className="contact_text">
          <h6>{t("contact_us")}</h6>
          <p>(800) 990-2705</p>
          <p>info@NCTMCO.com</p>
        </div>
      </div>
      <p className="footer_para">
        {t("privacy")} <span className="cursor-pointer">{t("privacy01")} </span>
        <span className="cursor-pointer"> {t("privacy02")}</span>
      </p>
      <hr />
      <div className="cookie_popup">
        <p>
          {t("why_cookie")}{" "}
          <span>
            <i
              className="fa-solid fa-chevron-down"
              onClick={() => {
                setOpen(true);
              }}
            ></i>
          </span>{" "}
        </p>
      </div>
      {open && <CookiePopup setOpen={setOpen} />}
      <div className={`scroll-to-top-button ${isVisible ? "show" : "hide"}`}>
        <IconButton aria-label="Scroll to Top" color="secondary" onClick={scrollToTop}>
          <KeyboardArrowUpIcon 
          className="scroll-to-top-icon" />
        </IconButton>
      </div>
    </footer>
  );
};

export default Footer;
