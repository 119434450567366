import React from "react";
import "./ModalComponent.css";

function AIServiceModalComponent({ onClose }) {
  return (
    <div
      className="modal-overlay"
      style={{ backgroundColor: "black", opacity: "0.9" }}
    >
      <div
        className="modal-content"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        <img
          style={{ width: "150px", height: "auto" }}
          className="modal-logo"
          src={require("../img/logo.png")}
          alt="logo"
        />
        <p>
          Thank you for completing the questionnaire. We will review the details
          and get back to you in the manner you detailed in questionnaire.We
          look forward to speaking with you soon.
        </p>
      </div>
    </div>
  );
}

export default AIServiceModalComponent;
