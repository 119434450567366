import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import SalesModalComponent from "../utils/SalesModalComponent";
import "./SalesModal.css";

function SalesModal() {
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-sales-modal">
      <div className="t-container">
        <div
          style={{
            marginTop: 0,
            width: "100%",
            paddingBottom: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <NavLink to="/">
            <img
              className="logo_testing"
              src={require("../img/logo.png")}
              alt="logo"
            />
          </NavLink>
        </div>
        <h1
          style={{
            textAlign: "center",
            fontSize: "30px",
            textShadow: "1px 1px 2px white",
          }}
        >
          Application:
        </h1>
        <h3
          style={{ marginBottom: "7px", fontSize: "25px", marginTop: "20px" }}
        >
          Position applying for: (Check all that apply)
        </h3>
        <div style={{ marginBottom: "20px", marginTop: "10px" }}>
          <div style={{ display: "flex" }}>
            <input type="checkbox" style={{ width: "auto" }} />
            <p style={{ marginLeft: "10px", fontSize: "20px" }}>
              Professional Business Developer (PBD)
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <input style={{ width: "auto" }} type="checkbox" />{" "}
            <p style={{ marginLeft: "10px", fontSize: "20px" }}>
              Sales Manager/Executive Position
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <input style={{ width: "auto" }} type="checkbox" />{" "}
            <p style={{ marginLeft: "10px", fontSize: "20px" }}>
              Professional Referral Developer
            </p>
          </div>
        </div>
        <h3 style={{ marginBottom: "10px", fontSize: "25px" }}>
          Personal Information:
        </h3>
        <div
          style={{
            fontFamily: "Poppins,sans-serif",
            display: "flex",
            flexDirection: "row",
            margin: "20px 0px",
            flexWrap: "wrap",
            maxWidth: "700px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              paddingBottom: "10px",
              paddingRight: "10px",
              maxWidth: "300px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label style={{ paddingBottom: "5px" }}>Name:</label>
            <input type="text" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "10px",
              maxWidth: "300px",
              paddingRight: "10px",
            }}
          >
            <label style={{ paddingBottom: "5px" }}>Address:</label>
            <input type="text" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "10px",
              paddingRight: "10px",
              maxWidth: "300px",
            }}
          >
            <label style={{ paddingBottom: "5px" }}>Phone:</label>
            <input type="text" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "20px",
              paddingRight: "10px",
              maxWidth: "300px",
            }}
          >
            <label style={{ paddingBottom: "5px" }}>Email:</label>
            <input type="text" />
          </div>
        </div>
        <h3 style={{ marginBottom: "10px", fontSize: "25px" }}>
          Professional Background:
        </h3>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            margin: "20px 0px",
            fontFamily: "Poppins,sans-serif",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              paddingBottom: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label style={{ paddingBottom: "5px" }}>Current Position:</label>
            <input type="text" />
          </div>
          <div
            style={{
              paddingBottom: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label style={{ paddingBottom: "5px" }}>
              Years Of Experience in B@B Sales:
            </label>
            <input type="text" />
          </div>
          <div
            style={{
              paddingBottom: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label style={{ paddingBottom: "5px" }}>
              Current Current Employer:
            </label>
            <input type="text" />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "Poppins,sans-serif",
          }}
        >
          <label style={{ paddingBottom: "5px" }}>
            Describe Your Current Sales Rate:
          </label>
          <textarea
            id="additional-info"
            style={{ marginBottom: "20px", height: "150px" }}
          ></textarea>
        </div>
        <h3 style={{ marginBottom: "10px", fontSize: "25px" }}>
          Your Ambitions:
        </h3>
        <div>
          <p
            style={{ paddingBottom: "10px", fontFamily: "Poppins,sans-serif" }}
          >
            What Interests You About Our Company Or Opportunity?
          </p>
          <textarea
            id="additional-info"
            style={{ marginBottom: "20px", height: "150px" }}
          ></textarea>
        </div>
        <div>
          <p
            style={{ paddingBottom: "10px", fontFamily: "Poppins,sans-serif" }}
          >
            What Are Your Career Goals?
          </p>
          <textarea
            id="additional-info"
            style={{ marginBottom: "20px", height: "150px" }}
          ></textarea>
        </div>
        <div>
          <p
            style={{ paddingBottom: "10px", fontFamily: "Poppins,sans-serif" }}
          >
            How Do You Plan To Achieve These Goals?
          </p>
          <textarea
            id="additional-info"
            style={{ marginBottom: "20px", height: "150px" }}
          ></textarea>
        </div>
        <div>
          <p
            style={{ paddingBottom: "10px", fontFamily: "Poppins,sans-serif" }}
          >
            What Is Your Main Reason For Applying?
          </p>
          <textarea
            id="additional-info"
            style={{ marginBottom: "20px", height: "150px" }}
          ></textarea>
        </div>
        <div>
          <p
            style={{ paddingBottom: "10px", fontFamily: "Poppins,sans-serif" }}
          >
            Additional Reasons:
          </p>
          <textarea
            id="additional-info"
            style={{ marginBottom: "20px", height: "150px" }}
          ></textarea>
        </div>
        <h3 style={{ marginBottom: "10px", fontSize: "25px" }}>
          Availability:
        </h3>
        <p>When can you start?</p>
        <input
          style={{
            backgroundColor: "orange",
            color: "black",
            width: "220px",
            fontSize: "20px",
            margin: "10px 0px",
          }}
          type="date"
        />
        <p style={{ marginTop: "10px" }}>
          Attach Resume (If You Are Only Applying For PRD, Resume Is Not
          Required).
        </p>
        <label>
          <input
            type="file"
            accept=".pdf, .doc, .docx"
            id="fileInput" // Add a unique id
            style={{
              display: "none",
            }}
          />
        </label>
        <button
          onClick={() => {
            const fileInput = document.getElementById("fileInput");
            if (fileInput) {
              fileInput.click(); // Trigger file input when button is clicked
            }
          }}
          style={{
            borderRadius: "20px",
            border: "2px dotted #333",
            backgroundColor: "transparent",
            display: "flex",
            marginTop: "15px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <span
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            <i className="fas fa-upload" style={{ marginRight: "10px" }}></i>
            Upload
          </span>
        </button>

        <h3
          style={{ marginTop: "20px", marginBottom: "10px", fontSize: "25px" }}
        >
          Agreement:
        </h3>
        <input
          type="checkbox"
          name="1"
          style={{ width: "auto", marginRight: "5px" }}
        />
        <p style={{ display: "inline" }}>
          I certify that the information provided in this application is true
          and complete to the best of my knowledge.
        </p>
        <button
          style={{ marginTop: "20px" }}
          className="submit_btn"
          onClick={handleModalOpen}
        >
          SUBMIT
        </button>
        {modalVisible && <SalesModalComponent onClose={handleModalClose} />}
      </div>
    </div>
  );
}

export default SalesModal;
