import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1E0928",
    },
    secondary: {
      main: "#CA2069",
    },
  },
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: "#2D2E2F",
        color: "#fff",
      },
    },
  },
  props: {
    MuiAppBar: {
      color: "inherit",
    },
    MuiTooltip: {
      arrow: true,
    },
  },
  typography: {
    h2: {
      fontSize: "3rem",
    },
    h1: {
      fontSize: "5rem",
    },
    h3: {
      fontSize: "2.5rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "2rem",
    },
  },
});

theme.typography.h3 = {
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.7rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.5rem",
  },
};

theme.typography.h2 = {
    fontSize: "2.7rem",
    fontWeight: 600,
    "@media (min-width:600px)": {
      fontSize: "3.0rem",
      fontWeight: "300",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
      fontWeight: 100,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.4rem",
    },
  };

  theme.typography.h1 = {
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.8rem",
      fontWeight: 100,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "4.0rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "5.0rem",
    },
  };