import React, { useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import "./Main.css";
import { useTranslation } from "react-i18next";
import emailjs from "emailjs-com";
import { ClipLoader } from "react-spinners";
import Footer from "./Footer";
import ReactPlayer from "react-player";

type MainProps = {
  startAnimate: boolean;
};

const Main = ({ startAnimate }: MainProps) => {
  const [currentState, setCurrentState] = useState<number>(0);
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState<any>(false);

  const formRef = useRef<HTMLFormElement>(null);
  const [done, setDone] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (formRef.current) {
      emailjs
        .sendForm(
          process.env.REACT_APP_PRIVATE_KEY_FIRST as string,
          process.env.REACT_APP_PRIVATE_KEY_SECOND as string,
          formRef.current,
          process.env.REACT_APP_PRIVATE_KEY_THIRD as string
        )
        .then(
          (result) => {
            console.log(result.text);
            setDone(true);
            setLoading(false);
            setTimeout(() => {
              setDone(false);
            }, 3000);
          },
          (error) => {
            setLoading(true);
            console.log(error.text);
          }
        );

      formRef.current.user_name.value = "";
      formRef.current.user_phone.value = "";
      formRef.current.user_email.value = "";
      formRef.current.user_subject.value = "";
      formRef.current.user_message.value = "";
    }
  };
  return (
    <main>
      {done && (
        <div
          className="alert"
          style={{ zIndex: 999, position: "fixed", top: 0, width: "100vw" }}
        >
          <div className="alert-content">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
            </svg>
            <p>Great! Will start conversation soon!</p>
          </div>
        </div>
      )}
      <div className="radial_container">
        <div
          id="main-section"
          className={`${startAnimate ? "animate-slide-up" : "video-init"}`}
        >
          <div id="vid2-container">
            <ReactPlayer
              url={
                i18n.language === "en"
                  ? require("../video/english.mp4")
                  : require("../video/spanish.mp4")
              }
              playing={true}
              loop={true}
              muted={true}
              controls={false}
              width="100%"
              height="auto"
            />
          </div>
        </div>

        <section id="commitment-line" className="commitment_flex">
          <div className="radial_circle1"></div>

          <div id="commitment-heading" className="commit_half">
                <Typography
                  variant="h1"
                  className="helvetica text_uppercase"
                >
                  {t("commitment")}
                  {t("commitment01")}
                  {t("commitment02")}..
                  <img src={require("../img/comma.png")} alt="" />
                </Typography>
          </div>
          <div className="commit_half">
            <p className="inter">
              {t("commitmentText")}
              <span>{t("commitmentText01")}</span>
            </p>
          </div>
          <div className="commit_full">
            <p className="inter">{t("commitmentText2")}</p>
          </div>
        </section>

        <section className="before_services_section">
          <Typography variant="h2">The twin pillars for AI:</Typography>
          <Typography variant="body1">Heightened Productivity & Unique customer experiences</Typography>
          <Typography variant="h3">We will assist you in both</Typography>
        </section>

        <section className="service" id="service">
          <div className="radial_circle2"></div>
          <div id="services-container">
            <Typography variant="h1" className="helvetica" id="servicesHeading">{t("service_head")}</Typography>
            <div className="services_flex_1">
              <img
                src={require("../img/service-1.png")}
                alt=""
                height="914px"
              />
              <img
                src={require("../img/service-2.png")}
                alt=""
                height="914px"
              />
              <img
                src={require("../img/service-3.png")}
                alt=""
                height="914px"
              />
            </div>
          </div>

          <div className="service_flex_2">
            <img src={require("../img/ai.jpg")} alt="image" />
            <div className="devTools_text helvetica">
              <Typography id="devtoolnsub text_uppercase" variant="h1">{t("developer_tool")}</Typography>
              <Typography variant="h2">{t("developer_subHead")}</Typography>
              <ul
                className="developer_list"
                style={{
                  fontSize: "2rem",
                  color: "lightgray",
                  paddingTop: "5px",
                  marginLeft: "24px",
                }}
              >
                <li>{t("developer_subHead01")}</li>
                <li>{t("developer_subHead02")}</li>
                <li>{t("developer_subHead03")}</li>
                <li>{t("developer_subHead04")}</li>
                <li>{t("developer_subHead05")}</li>
              </ul>
            </div>
          </div>
          <div className="service_flex_2 middle">
            <div className="devTools_text helvetica">
              <Typography id="devtoolnsub text_uppercase" variant="h1">{t("devops_tool")}</Typography>
              <Typography variant="h2">{t("devops_subHead")}</Typography>
              <ul
                className="devops_list"
                style={{
                  fontSize: "2rem",
                  color: "lightgray",
                  paddingTop: "5px",
                  marginLeft: "24px",
                }}
              >
                <li style={{ paddingTop: "5px" }}>{t("devops_subHead01")}</li>
                <li style={{ paddingTop: "5px" }}>{t("devops_subHead02")}</li>
                <li style={{ paddingTop: "5px" }}>{t("devops_subHead03")}</li>
                <li style={{ paddingTop: "5px" }}>{t("devops_subHead04")}</li>
                <li style={{ paddingTop: "5px" }}>{t("devops_subHead05")}</li>
              </ul>
            </div>
            <img src={require("../img/programmer.png")} alt="image" />
          </div>
          <div className="service_flex_2">
            <img
              src={require("../img/software.jpg")}
              style={{ objectFit: "cover" }}
              alt="image"
            />
            <div className="devTools_text helvetica">
              <Typography id="devtoolnsub text_uppercase" variant="h1">{t("testing_tool")}</Typography>
              <Typography variant="h2">{t("testing_subHead")}</Typography>
              <ul
                className="testing_list"
                style={{
                  fontSize: "2rem",
                  color: "lightgray",
                  paddingTop: "5px",
                  marginLeft: "24px",
                }}
              >
                <li style={{ paddingTop: "5px" }}>{t("testing_subHead01")}</li>
                <li style={{ paddingTop: "5px" }}>{t("testing_subHead02")}</li>
                <li style={{ paddingTop: "5px" }}>{t("testing_subHead03")}</li>
                <li style={{ paddingTop: "5px" }}>{t("testing_subHead04")}</li>
                <li style={{ paddingTop: "5px" }}>{t("testing_subHead05")}</li>
              </ul>
            </div>
          </div>
        </section>

        <section id="contract-n-consult">
          <div className="contract_bg">
            <Typography className="text_uppercase" variant="h3">{t("contract")}</Typography>
            <Typography className="text_uppercase helvetica" variant="h4">{t("contract_subheading")}</Typography>
            <p className="helvetica">{t("contract_desc")}</p>
            <p className="helvetica">{t("contract_desc2")}</p>
            <button className="consulting_btn helvetica text_uppercase">
              {t("connect_btn")}{" "}
            </button>
          </div>
        </section>
        <section className="workshop_events">
          <div className="radial_circle5"></div>
          <Typography className="helvetica text_uppercase" variant="h1">{t("events_head")}</Typography>
          <div className="workshop_flex">
            <div className="workshop_img">
              <img src={require("../img/workshop.png")} alt="" height="864px" />
            </div>
            <div className="workshop_details">
              <div>
                <Typography className="bold text_uppercase helvetica" variant="h4">{t("workshop")}</Typography>
                <p>{t("w_desc")} </p>
              </div>
              <div>
                <Typography className="bold text_uppercase helvetica" variant="h4">{t("event")}</Typography>
                <p>{t("e-year")}</p>
                <p>{t("e_desc")}</p>
              </div>
              <div className="follow_text pinkcolor text_uppercase">
                {t("full_detail")}
              </div>
            </div>
          </div>
        </section>

        <section className="investors" id="investor">
          <Typography className="text_uppercase" variant="h2">{t("invest")}</Typography>
          <Typography className="helvetica" variant="h4">{t("recevie_d")}</Typography>
          <form className="contact_form" ref={formRef} onSubmit={handleSubmit}>
            <input
              required
              type="text"
              name="user_name"
              className="name_input"
              placeholder={t("*Name")}
            />
            <input
              type="text"
              className="phone_input"
              name="user_phone"
              placeholder={t("Phone")}
            />
            <input
              type="text"
              style={{ width: "100%" }}
              name="user_subject"
              placeholder={t("Subject")}
            />
            <input
              required
              type="email"
              name="user_email"
              placeholder={t("*Email")}
            />
            <textarea
              required
              placeholder={t("*Message...")}
              rows={6}
              name="user_message"
            ></textarea>
            <button className="submit_btn text_uppercase" type="submit">
              {loading ? (
                <ClipLoader color="#ffffff" size={15} loading={loading} />
              ) : (
                t("submit_btn")
              )}
            </button>
          </form>
        </section>
        <Footer />
      </div>
    </main>
  );
};

export default Main;
