import React, { useEffect } from "react";
import "./TestingService1.css";
import { NavLink, useNavigate } from "react-router-dom";

function TestingService1() {
  const Navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="t-ultimate-container">
      <div className="t-container">
        <div
          style={{
            paddingTop: "30px",
            marginTop: 0,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <NavLink to="/">
            <img
              className="logo_testing"
              src={require("../../img/logo.png")}
              alt="logo"
            />
          </NavLink>
          <h2 className="t_head1">Manual Testing Services</h2>
          <h3 className="t_head2">
            The first step to effective testing for your program launch
          </h3>
        </div>

        <p className="t-description">
          Are you ready to launch your product or software? Wondering what your
          end user will experience? We can assist on many levels in providing
          much needed details, as well as defining what that process will look
          like.
          <br />
          <br />
          Manual testing is a necessary and required area that is tedious and
          time consuming for many developers. We have seen in working with many
          businesses that this job is performed by companies internal
          developers. Why have your developers doing manual testing when it can
          be done through a service like ours. We can do this professionally and
          cost effectively for a much lower hourly rate than you might expect.
          <br />
          <br />
          Our overall goal at Noctem Corp is to assist your engineering
          operations to concentrate on development. We provide the tools and
          services that can maintain creation in your engineering department as
          your top priority. Now with the addition of our manual testing
          services, at an affordable rate, without sacrificing quality, you can
          accomplish both areas effectively.
          <br />
          <br />
          Noctem Corp is a QA manual testing professional, preferred by both
          small and large corporations. Our team has experienced professionals
          with ample experience testing numerous SaaS products in E-Learning.
          E-Commerce, E-Government, Entertainment & Media, MarTech, FinTech, and
          more. Manual testing is an important step before moving on to
          automated testing. We can help you visualize the performance for your
          end-user and test every feature ensuring a thorough investigation in
          every possible scenario your users could encounter. We are very
          thorough in our process and in helping you identify every situation,
          possibility or crash that could occur.
          <br />
          <br />
          We are committed to assisting developers in better understanding the
          demands of their customers by providing a well-defined process from
          beginning to end. When we work with your company, every step of our
          process will provide standards that guarantee the implementation of
          best practices and a high-quality finished product. We will be
          extremely helpful in saving you money and in identifying minor flaws
          before they develop into significant issues.
        </p>

        <h3 className="f-text">
          Save money <br />
          Meet deadlines <br />
          Keep developers developing
        </h3>

        <div className="tg-container">
          <p>
            <span style={{ color: "yellow" }}>Dramatic savings!</span>
            <br />
            <span style={{ color: "tomato" }}>
              Use those savings to reward your
            </span>
            <br />
            <span style={{ color: "deeppink" }}>
              developers or to increase your <br /> development team!
            </span>
          </p>
          <img src="tg1.png" alt="testing-image" />
          <img src="tg2.png" alt="testing-image" />
          <p>
            <span style={{ color: "yellow" }}>
              {" "}
              Learn how our unique process to
            </span>{" "}
            <br />
            <span style={{ color: "tomato" }}>
              help you save even more with
            </span>{" "}
            <br />
            <span style={{ color: "deeppink" }}>our 1-to-5-year POA:</span>
          </p>
        </div>

        <p className="t-description2">
          We work with you on a Point of Agreement (POA) term. The longer the
          term of our agreement, the more you save. Our terms range from one to
          five years. By working with our services longer, you can decrease the
          hourly wage we charge for working on your project.
          <br />
          <br />
          Very often we can provide assistance before you even begin creation of
          your project with our additional CI/CD & other consulting services.
          Ask your consultant how we can assist in minimizing significant bugs &
          testing measures with these services as well. Noctem services
          combined, can greatly diminish overhead costs and time-consuming
          delays in the launch of your product. Complete the assessment below to
          receive a quote and begin the process today.
        </p>

        <h1 className="t-text">
          Obtain a free quote & assessment on your project testing needs.
        </h1>

        <button
          className="submit_btn"
          type="submit"
          onClick={() => Navigate("/testingmodal")}
        >
          COMPLETE FORM ONLINE
        </button>
      </div>
    </div>
  );
}

export default TestingService1;
